/* Dark Theme for User Dashboard - Updated Spacing and Placement */

/* Overall container */
.user-dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1a202c; /* Dark background */
  color: #e2e8f0;            /* Light text color */
  box-sizing: border-box;
}

/* Form panel (for adding a new user) */
.user-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background: #2d3748;       /* Dark panel background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  margin: 20px auto;         /* Center form with vertical spacing */
}

/* Inputs and select elements */
.input,
.select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #4a5568;
  font-size: 14px;
  background-color: #1a202c; /* Dark input background */
  color: #e2e8f0;
  box-sizing: border-box;
}

/* Buttons for adding and fetching users */
.user-submit-button,
.fetch-users-button {
  background-color: #48bb78; /* Accent green */
  color: #1a202c;            /* Dark text for contrast */
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.2s;
  margin-top: 10px;
}

.user-submit-button:hover,
.fetch-users-button:hover {
  background-color: #38a169;
}

/* User list container */
.user-list {
  padding: 20px;
  max-width: 500px;
  margin: 20px auto; /* Center the list with vertical spacing */
}

/* Individual user item styling */
.user-item {
  background-color: #2d3748; /* Dark panel background */
  border: 1px solid #4a5568; /* Muted dark border */
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 12px;       /* Space between items */
}

/* Logout button */
.logout-button {
  background-color: #48bb78;
  color: #1a202c;
  padding: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
  align-self: center;       /* Center the button horizontally */
  margin: 20px auto;        /* Vertical spacing and centering */
}

.logout-button:hover {
  background-color: #38a169;
}

/* User table styling */
.user-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;        /* Center table with vertical spacing */
  background-color: #2d3748; /* Dark panel background for table */
  color: #e2e8f0;
}

.user-table th,
.user-table td {
  border: 1px solid #4a5568;
  padding: 10px;
  text-align: left;
}

.user-table th {
  background-color: #2c2f35; /* Slightly darker header */
  color: #e2e8f0;
}

.user-table tr:nth-child(even) {
  background-color: #2c2f35;
}

.user-table tr:hover {
  background-color: #3a4151;
}
