/* Navbar.css - Enhanced Dark Theme */

/* Dark gradient background for the navbar */
.navbar-container {
  background: linear-gradient(90deg, #1a202c 0%, #2d3748 100%); /* Dark gradient */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 999;
}

/* Brand styling with subtle glow */
.navbar-brand {
  color: #81e6d9; /* Teal-ish accent color */
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  text-shadow: 0 0 5px rgba(129, 230, 217, 0.4); /* Soft glow */
  transition: text-shadow 0.3s ease;
}

/* Subtle hover effect on brand */
.navbar-brand:hover {
  text-shadow: 0 0 10px rgba(129, 230, 217, 0.7);
}

/* Navigation links list styling */
.nav-links {
  list-style: none;
  display: flex;
  gap: 24px; /* Slightly larger gap for spacing */
  margin: 0;
  padding: 0;
}

/* Individual link styling */
.nav-link {
  color: #e2e8f0; /* Light text for better contrast on dark background */
  text-decoration: none;
  font-size: 16px;
  position: relative;
  padding: 4px 6px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Hover effect for links - glow & highlight background */
.nav-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #81e6d9; /* Matches brand accent color */
  transition: width 0.3s ease;
}

.nav-link:hover {
  color: #81e6d9;
  background-color: rgba(255, 255, 255, 0.1); /* Subtle highlight */
}

.nav-link:hover::after {
  width: 100%;
}

/* Hamburger menu button (hidden by default) */
.hamburger-menu {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #f7fafc; /* Light text color to match overall theme */
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* Subtle hover effect on the hamburger icon */
.hamburger-menu:hover {
  transform: scale(1.2);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  /* Show the hamburger menu on small screens */
  .hamburger-menu {
    display: block;
  }

  /* Sidebar styling for mobile view */
  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    background: linear-gradient(180deg, #1a202c 0%, #2d3748 100%);
    flex-direction: column;
    justify-content: center;  /* Vertically center the items */
    align-items: center;      /* Horizontally center the items */
    gap: 24px;                /* Good spacing between items */
    padding: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%); /* Hide sidebar by default */
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.8);
  }

  /* When the sidebar is open */
  .nav-links.show {
    transform: translateX(0);
  }

  /* Adjust navigation link styling for mobile */
  .nav-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
}
