/* Tracker.css */

/* Overall container */
.tracker-container {
  background-color: #1a202c;
  color: #e2e8f0;
  min-height: 100vh;
  box-sizing: border-box;

  /* So that .date-filter-container can be absolutely positioned inside */
  position: relative;
}

.tracker-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #f6e05e;
  font-size: 1.8rem;
}

/* Summary Boxes */
.summary-boxes {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.summary-box {
  background-color: #2d3748;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  width: 220px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  text-align: center;
}

.summary-box h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: #f6e05e;
}

.summary-box p {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: #81e6d9;
}

/* Toggle button container (Open/Close Date Filter) */
.filter-toggle {
  text-align: center;
  margin-bottom: 1rem;
}

.filter-toggle button {
  background-color: #2d3748;
  color: #f6e05e;
  border: 1px solid #4a5568;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

/* Slider Toggle */
.slider-container {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 40px;
  background-color: #2c2f35;
  border-radius: 20px;
  margin: 0 auto 1rem auto;
  font-size: 0;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.slider-container input[type="radio"] {
  display: none;
}

.slider-container label {
  display: inline-block;
  width: 50%;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: #f6e05e;
  font-size: 0.95rem;
  font-weight: 500;
  user-select: none;
}

.slider-container .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 40px;
  background-color: #4BC0C0;
  border-radius: 20px;
  transition: all 0.3s ease;
  z-index: 1;
}

/* When productiveTime is checked, move slider to right */
.slider-container input[id="productiveTime"]:checked~.slider {
  left: 50%;
  background-color: #36A2EB;
}

.chart-container {
  position: relative;
  width: 90%;
  max-width: 900px;
  margin: 0 auto 20px auto;
  background-color: #2d3748;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

  /* A default height for desktop */
  height: 500px;
}

/* Tracker Table */
.tracker-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  background-color: #2d3748;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.tracker-table th,
.tracker-table td {
  padding: 12px;
  border: 1px solid #4a5568;
  text-align: left;
  box-sizing: border-box;
}

.tracker-table th {
  background-color: #2c2f35;
  color: #f6e05e;
  font-weight: 600;
  font-size: 0.95rem;
}

.tracker-table tr:nth-child(even) {
  background-color: #333a48;
}

.tracker-table tr:hover {
  background-color: #3a4151;
}

/* Nested sub-table for apps */
.app-subtable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
  background-color: #2d3748;
}

.app-subtable th,
.app-subtable td {
  border: 1px solid #4a5568;
  padding: 6px;
  text-align: left;
  font-size: 13px;
  box-sizing: border-box;
}

.app-subtable th {
  background-color: #2c2f35;
  color: #f6e05e;
  font-weight: 500;
}

.app-subtable tr:nth-child(even) {
  background-color: #333a48;
}

.app-subtable tr:hover {
  background-color: #3a4151;
}

/* App Type Badge */
.app-type-badge {
  margin-left: 8px;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.8rem;
  text-transform: capitalize;
  font-weight: 600;
}

.app-type-badge.productive {
  background-color: #4BC0C0;
  color: #1a202c;
}

.app-type-badge.neutral {
  background-color: #cbd5e0;
  color: #2d3748;
}

.artist-filter select {
  background-color: #2c2f35;
  color: #e2e8f0;
  border: 1px solid #4a5568;
  padding: 6px;
  border-radius: 4px;
  /* Optional: Remove the default arrow in some browsers and add a custom one */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


/* Responsive styling for smaller screens */
@media screen and (max-width: 768px) {

  .tracker-table,
  .tracker-table thead,
  .tracker-table tbody,
  .tracker-table th,
  .tracker-table td,
  .tracker-table tr {
    display: block;
  }

  .tracker-table tr {
    margin-bottom: 10px;
    border: 2px solid #4a5568;
  }

  .tracker-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .tracker-table td {
    border: none;
    border-bottom: 1px solid #4a5568;
    position: relative;
    padding-left: 50%;
    text-align: right;
    white-space: normal;
  }

  .tracker-table td::before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    content: attr(data-label);
    color: #f6e05e;
  }
}

/* ========================================= */
/* NEW STYLES FOR OPEN/CLOSE DATE FILTER PANEL */
/* ========================================= */

.date-filter-container {
  /* Absolutely positioned so it overlaps without pushing content */
  position: absolute;

  /* Adjust the vertical position as needed (e.g., 200px from the top) */
  top: 290px;

  /* Center horizontally */
  left: 50%;
  transform: translateX(-50%);

  /* High z-index so it floats above other elements */
  z-index: 9999;

  background-color: #2d3748;
  border: 1px solid #4a5568;
  border-radius: 8px;
  padding: 12px;
  color: #f6e05e;
  width: 250px;
  /* fixed width to avoid layout shift */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}

/* The heading of the date filter (with an X close button) */
.date-filter-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 8px 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.close-filter-btn {
  background: none;
  border: none;
  color: #f6e05e;
  font-size: 1rem;
  cursor: pointer;
}

/* Preset list (Today, Yesterday, etc.) */
.preset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.preset-list li {
  padding: 5px 0;
  cursor: pointer;
  color: #e2e8f0;
}

.preset-list li:hover {
  color: #81e6d9;
}

/* Manual date pickers stacked vertically */
.date-pickers {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 10px;
}

.date-pickers label {
  display: flex;
  flex-direction: column;
  color: #f6e05e;
}

.artist-filter {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-top: 10px;
}

.artist-filter label {
  color: #f6e05e;
}

/* More input styling if desired */
.date-filter-container input[type="date"],
.date-filter-container input[type="text"] {
  background-color: #2c2f35;
  border: 1px solid #4a5568;
  border-radius: 4px;
  color: #e2e8f0;
  padding: 4px;
  margin-top: 4px;
}

.tracker-container {
  text-align: center;
}



@media screen and (max-width: 480px) {


  /* Force slider container to be centered */
  .slider-container {
    display: block;
    margin: 0 auto 1rem auto !important;
    width: 90%;
    max-width: 220px;
  }

  /* Adjust chart container for mobile */
  .chart-container {
    width: 95%;
    max-width: 100%;
    margin: 0 auto 20px auto;
    padding: 10px;
    height: 300px;
    /* Increase height just for mobile */
  }

  .chart-container canvas {
    width: 100% !important;
    height: 100% !important;
  }
}