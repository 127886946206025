/* FileUploadContainer.css - Enhanced Dark Theme with Vibrant Accents */

/* Dropzone area */
.dropzone {
  border: 2px dashed #4a5568;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  transition: border-color 0.2s;
  width: 300px;       /* Fixed width */
  height: 100px;      /* Fixed height */
  background-color: #1a202c; /* Dark background */
  color: #cbd5e0;     /* Base light text */
}

.dropzone:hover {
  border-color: #63b3ed; /* Blue accent on hover */
}

/* Selected files list */
.selected-files-list {
  max-height: 150px;
  overflow-y: auto;
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  background-color: #2d3748;
  border: 1px solid #4a5568;
}

/* Individual file items */
.selected-file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin: 5px 0;
  border-radius: 4px;
  background-color: #1a202c; /* Slightly darker container */
  overflow: hidden;
}

/* Make file name stand out in a bright color */
.selected-file-item .file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
  color: #f6e05e; /* Vibrant yellow accent for file names */
  transition: color 0.2s;
}

.selected-file-item .file-name:hover {
  color: #ecc94b; /* Slightly darker yellow on hover */
}

/* Remove file button - use red accent */
.remove-file-btn {
  background-color: #e53e3e;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.remove-file-btn:hover {
  background-color: #c53030;
}

/* 
  Upload, Copy Link, Reset Link buttons
  - Each has a different accent color
  - Feel free to unify or swap these colors as you wish 
*/
.upload-button {
  background-color: #63b3ed;  /* Blue */
  color: #1a202c;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  margin: 5px;
  transition: background-color 0.2s, opacity 0.2s, transform 0.2s;
}

.upload-button:hover {
  background-color: #4299e1;  /* Slightly darker blue on hover */
  transform: translateY(-1px);
}

/* Orange accent for copy link */
.copy-link-button {
  background-color: #ed8936;  /* Orange */
  color: #1a202c;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  margin: 5px;
  transition: background-color 0.2s, opacity 0.2s, transform 0.2s;
}

.copy-link-button:hover {
  background-color: #dd6b20;  /* Darker orange on hover */
  transform: translateY(-1px);
}

/* Yellow accent for reset link */
.reset-link-button {
  background-color: #f6e05e;  /* Yellow */
  color: #1a202c;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  margin: 5px;
  transition: background-color 0.2s, opacity 0.2s, transform 0.2s;
}

.reset-link-button:hover {
  background-color: #ecc94b; /* Slightly darker yellow on hover */
  transform: translateY(-1px);
}

/* Disabled state */
.upload-button:disabled,
.copy-link-button:disabled,
.reset-link-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Hide upload button initially (if needed) */
.upload-button.hidden {
  display: none;
}

/* Uploaded links container */
.uploaded-links-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

/* Individual uploaded link */
.uploaded-link {
  text-decoration: none;
  color: #63b3ed;    /* Lighter blue accent */
  margin: 5px 0;
  transition: color 0.2s, transform 0.2s;
}

.uploaded-link:hover {
  color: #4299e1;    /* Slightly darker blue on hover */
  transform: translateY(-1px);
}

/* 
  .images-and-button 
  - We use a column layout so the images are on top, 
    and the button is just below, always visible. 
*/
.images-and-button {
  display: flex;
  flex-direction: column; /* vertical stack */
  align-items: flex-start;
  gap: 8px;               /* space between container & button */
}

/* Thumbnails container: limit height + scroll if needed */
.images-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 200px; /* or 150px, etc. */
  overflow-y: auto;  /* scroll if too many images */
  margin-top: 5px;
}

/* Each thumbnail container */
.image-preview-container {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;           /* prevent shrinking if container is narrow */
  background-color: #2d3748; /* optional dark background for placeholder */
}

/* The thumbnail image */
.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover; /* fill container while keeping aspect ratio */
}

/* The small "x" remove button in the corner */
.remove-image-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #e53e3e;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Reaffirming the Upload button style (in case of overlap with classes) */
.upload-button {
  background-color: #63b3ed;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s, transform 0.2s;
}

.upload-button:hover {
  background-color: #4299e1;
  transform: translateY(-1px);
}

/* Global mobile responsiveness adjustments */
@media (max-width: 768px) {
  .dropzone {
    width: auto;
    margin: 10px;
    padding: 15px;
  }

  .selected-files-list,
  .uploaded-links-container {
    margin: 10px;
  }

  .upload-button,
  .copy-link-button,
  .reset-link-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .selected-file-item,
  .uploaded-link,
  p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .upload-button,
  .copy-link-button,
  .reset-link-button {
    padding: 6px 12px;
    font-size: 13px;
  }

  .selected-file-item,
  .uploaded-link,
  p {
    font-size: 13px;
  }
}
