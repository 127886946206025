/* AdminScreenshots.css */
.admin-screenshots-container {
  background-color: #1a202c;
  color: #e2e8f0;
  min-height: 100vh;
  box-sizing: border-box;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Duration Filter */
.current-duration {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #e2e8f0;
}

.duration-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.duration-form label {
  font-size: 16px;
  color: #e2e8f0;
}

.duration-form select {
  padding: 8px;
  font-size: 14px;
  background-color: #2d3748;
  /* Dark background */
  border: 1px solid #4a5568;
  border-radius: 4px;
  color: #ffffff;
  /* White text */
  box-sizing: border-box;
  transition: border-color 0.2s ease;
}

.duration-form select:focus {
  border-color: #48bb78;
  outline: none;
}

.duration-form button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #48bb78;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.duration-form button:hover {
  background-color: #38a169;
}

/* Filters */
.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.filters input,
.filters select {
  padding: 8px;
  font-size: 14px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  border-radius: 4px;
  color: #cbd5e0;
  box-sizing: border-box;
  transition: border-color 0.2s ease;
}

.filters input:focus,
.filters select:focus {
  border-color: #48bb78;
  outline: none;
}

/* Screenshots Grid */
.screenshots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.screenshot-card {
  background: #2d3748;
  border: 1px solid #4a5568;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease;
}

.screenshot-image {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.screenshot-info {
  padding: 10px;
}

.screenshot-info h4 {
  margin: 0 0 5px;
  font-size: 16px;
}

.screenshot-info p {
  margin: 2px 0;
  font-size: 14px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  position: relative;
  padding: 0;
  border-radius: 5px;
  max-width: 80%;
  border: 5px solid #48bb78;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  background: #1a202c;
}

.modal-image {
  display: block;
  max-width: 100%;
  max-height: 80vh;
  margin: 0 auto;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background 0.3s ease, transform 0.3s ease;
  /* Ensure it’s above the lens container */
  z-index: 999999;
}


.modal-close:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

/* Magnifier Styles */
.magnifier-container {
  position: relative;
  display: inline-block;
  /* This is crucial: anything outside the container is hidden. */
  overflow: hidden;
  /* Optionally, a lower z-index if you have buttons on top. */
  z-index: 1;
}


/* Make sure the lens has no fixed width/height here, or remove them if you had any. */
/* Magnifier lens behind other elements */
.magnifier-lens {
  display: none;
  position: absolute;
  z-index: 1;
  /* lens behind buttons */
  border-radius: 50%;
  border: 2px solid #48bb78;
  overflow: hidden;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Buttons container on top of lens */
.popup-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 9999;
  /* ensure it's above the lens */
}

/* Buttons themselves */
.action-button {
  /* same as your current styles */
  background: rgba(0, 0, 0, 0.6);
  border: none;
  color: #fff;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.3s ease;
}

.action-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 480px) {

  /* Hide magnifier on mobile */
  .magnifier-lens {
    display: none !important;
  }

  /* Make modal content take more space on mobile */
  .modal-content {
    width: 90%;
    /* allow it to be wider */
    max-width: 90%;
    /* remove smaller constraints */
    margin: 0 auto;
    /* center horizontally */
    border: 2px solid #48bb78;
    /* thinner border so image can be bigger */
  }

  /* Make the image larger within the modal */
  .modal-image {
    max-width: 100%;
    max-height: 80vh;
    /* let it be taller on small screens */
    margin: 0 auto;
    /* center if there's any horizontal space */
  }

  /* Position the close button in the top-right corner */
  .modal-close {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 30px;
    z-index: 999999;
    /* ensure it stays on top of other elements */
  }

  /* Position the action buttons (like delete) to avoid overlap */
  .popup-actions {
    position: absolute;
    bottom: 10px;
    /* Place them 10px from the bottom */
    right: 10px;
    /* Right-align */
    gap: 5px;
    transform: scale(0.9);
    /* optional scaling for smaller size on mobile */
    z-index: 999998;
    /* ensure it's above the image but below the close button */
  }

  /* Adjust button size as needed */
  .action-button {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }

}