/******************************************/
/* Page & Layout                          */
/******************************************/
.updates-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1a202c; /* Dark background */
  color: #e2e8f0;            /* Light text */
}

.updates-content {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.updates-content h2 {
  margin-bottom: 20px;
}



/******************************************/
/* Filters                                */
/******************************************/
.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.filter-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.filter-item label {
  font-weight: 500;
}

.filter-item select,
.filter-item input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #4a5568;
  background-color: #2d3748;
  color: #e2e8f0;
}

.filter-buttons {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.filter-buttons button {
  padding: 10px 15px;
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.filter-buttons button:hover {
  background-color: #38a169;
}

/******************************************/
/* Card Grid Layout                       */
/******************************************/
.updates-list {
  display: grid;
  gap: 20px;
  /* 1 column on very small screens */
  grid-template-columns: 1fr;
}

/* 2 columns at >= 480px */
@media (min-width: 480px) {
  .updates-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 3 columns at >= 768px */
@media (min-width: 768px) {
  .updates-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* 4 columns at >= 1024px */
@media (min-width: 1024px) {
  .updates-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

/******************************************/
/* Card Styling (Square)                  */
/******************************************/
.update-card {
  aspect-ratio: 1 / 1; /* Force square cards */
  background-color: #2d3748;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
}

/*
  Use a grid layout inside the card to
  ensure the top (date/artist), image,
  and bottom (project name) each have space.
*/
.update-card-inner {
  display: grid;
  grid-template-rows: auto 1fr auto; 
  width: 100%;
  height: 100%;
  padding: 10px;
}

/* Top row: date & artist */
.update-card-top {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.update-card-date,
.update-card-artist {
  color: #a0aec0;
}

/* Middle row: single image (flex area) */
.update-card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide overflow just in case image is large */
}

.update-card-image img {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
}

.no-image {
  color: #718096;
  font-style: italic;
}

/* Bottom row: project name */
.update-card-bottom {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.update-card-project {
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}

/******************************************/
/* If No Updates                          */
/******************************************/
.no-updates {
  margin-top: 20px;
  font-style: italic;
  text-align: center;
}

/******************************************/
/* Modal & Slider Styling                 */
/******************************************/

/* Dark overlay that covers the entire viewport, centering the modal. */
.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* The modal itself */
.image-modal {
  position: relative;
  width: 90%;
  max-width: 1200px;       /* A bit wider for large images */
  max-height: 90vh;       /* Keep height so it doesn't overflow screen */
  background-color: #2d3748;
  border-radius: 8px;
  padding: 20px;
  overflow: hidden;       /* Hide any accidental overflow */
  display: flex;          /* So we can place the close button easily */
  flex-direction: column;
}

/* Close button in the top-right corner. */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  padding: 8px 12px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10000;
}

.modal-close-button:hover {
  background-color: #38a169;
}

/* 
  Each slide container in the slick carousel.
  We set a flexible height, center the image, 
  and avoid extra scrollbars.
*/
.slider-image-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 70vh;     /* Enough space for tall images */
  max-height: 80vh; /* Clamped so large images fit */
  overflow: hidden;
}

/* The actual image in each slide. */
.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Modal header to position download and close buttons */
.modal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -10px;
  margin-right: -50px;
}

/* Download button styling */
.download-button {
  background-color: #48bb78; /* Accent green */
  color: #1a202c;            /* Dark text */
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  margin-right: 10px;        /* Space between download & close button */
  transition: background-color 0.2s ease;
}

/* Hover effect for download button */
.download-button:hover {
  background-color: #38a169;
}

/* Close button styling */
.modal-close-button {
  background-color: #e53e3e;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

/* Hover effect for close button */
.modal-close-button:hover {
  background-color: #c53030;
}
