/* src/css/applist.css */

.applist-container {
  background-color: #1a202c;
  color: #e2e8f0;
  min-height: 100vh;
  box-sizing: border-box;
}

.applist-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #f6e05e;
}

/* App Search */
.app-search {
  text-align: center;
  margin-bottom: 20px;
}

.app-search input {
  width: 300px;
  max-width: 90%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #4a5568;
  background-color: #2d3748;
  color: #e2e8f0;
  font-size: 14px;
}

/* Slider container for App Type Filter */
.slider-container {
  position: relative;
  display: block;
  /* Make it a block-level element */
  width: 220px;
  height: 40px;
  background-color: #2c2f35;
  border-radius: 20px;
  margin: 10px auto 20px auto;
  /* 'auto' on left & right centers the block */
  font-size: 0;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.slider-container input[type="radio"] {
  display: none;
}

.slider-container label {
  display: inline-block;
  width: 50%;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: #f6e05e;
  font-size: 0.95rem;
  font-weight: 500;
  user-select: none;
}

.slider-container .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 40px;
  background-color: #4BC0C0;
  border-radius: 20px;
  transition: all 0.3s ease;
  z-index: 1;
}

.slider-container input[id="productive"]:checked~.slider {
  left: 50%;
  background-color: #36A2EB;
}

/* App List Table */
.applist-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto;
  background-color: #2d3748;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.applist-table th,
.applist-table td {
  padding: 12px;
  border: 1px solid #4a5568;
  text-align: left;
  box-sizing: border-box;
}

.applist-table th {
  background-color: #2c2f35;
  color: #f6e05e;
  font-weight: 600;
}

.applist-table tr:nth-child(even) {
  background-color: #333a48;
}

.applist-table tr:hover {
  background-color: #3a4151;
}

/* Style the app type dropdown within the app list table */
.applist-container select {
  background-color: inherit;
  color: inherit;
  border: 1px solid #4a5568;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 13px;
  font-weight: bold;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.applist-container select::-ms-expand {
  display: none;
}

.applist-container select option {
  background-color: #2d3748;
  color: #e2e8f0;
}